import { Box, IconButton, Link, Typography } from "@mui/material";
import "./Header.css";

import WhatsAppIcon from "@mui/icons-material/WhatsApp";

function Header() {
  return (
    <Box
      sx={{
        height: "100vh !important",
        position: "relative",
        // border: "5px solid green",
        overflow: "hidden",
      }}
    >
      <video autoPlay muted loop id="myVideo">
        <source src="./lastVideo.mp4" type="video/mp4" />
      </video>

      <Box
        sx={{
          bgcolor: "transparent",
          height: { xs: "350px", sm: "450px", md: "700px" },
          width: { xs: "350px", sm: "450px", md: "700px" },
          position: "absolute",
          bottom: { xs: "15%", sm: "5%", md: "10%" },
          left: "50%",
          transform: "translate(-50%,0%)",
          // width: { xs: "300px",sm:"400px", md: "500px" },
          // bottom: {xs:"-2%", sm:"-5%"},
          // height: { xs: "300px",sm:"400px",md: "500px" },
          // top: "50%",
          // left: "50%",
          // transform: "translate(-50%,-50%)",
          // border: "2px solid green",
        }}
      >
        {/* <img src="./images/backgroundBasicWithEdit.png" className="container" alt="media"/> */}
        <img src="./images/background4.png" className="container" alt="media" />

        {/* nodes */}
        <Link href="https://about.qudraat.com">
          <Box
            className="item0"
            sx={{
              width: { xs: "50px", sm: "65px", md: "85px" },
              height: { xs: "100px", sm: "125px", md: "190px" },
              // border: "2px solid red",


              position: "absolute",
              bottom: { xs: "12%", sm: "13%", md: "14%" },
              // left: "50%",
            }}
          >
            {/* <Typography
              variant="h5"
              component="h5"
              sx={{
                position: "absolute",
                bottom: { xs: "-10%", sm: "-3%", md: "-5%" },
                left: { xs: "50%", md: "50%" },
                transform: "translate(-50%,0)",
                color: "white",
                letterSpacing: "3px",
                textTransform: "uppercase",
                fontSize: { xs: "20px", md: "25px" },
                fontWeight: "bold",
              }}
            >
              qudraat
            </Typography> */}

            <Typography
              variant="p"
              component="p"
              sx={{
                position: "absolute",
                bottom: { xs: "-15%", sm: "-13%", md: "-12%" },
                // bottom: { xs: "-25%", sm: "-15%", md: "-20%" },
                left: { xs: "50%" },
                transform: "translate(-50%,0)",
                color: "white",
                letterSpacing: { xs: "1px", md: "3px" },
                textTransform: "uppercase",
                fontSize: "15px",
                minWidth: "200px",
              }}
            >
              {/* meet the future */}
              Let's Explore
            </Typography>
            {/* <h3>  KODRAAT </h3> */}
            {/* <p>  MEET THE FUTURE </p> */}
          </Box>
        </Link>

        <Box
          className="item item6"
          sx={{
            bottom: { xs: "30%", sm: "30%", md: "31%" },
            right: { xs: "19%", sm: "18.5%", md: "19%" },
            width: { xs: "40px", sm: "50px", md: "80px" },
            height: { xs: "40px", sm: "50px", md: "80px" },
          }}
        >
          <Link href="https://mart.qudraat.com">
            <img src="./images/2.png" alt="media" />
            <Typography
              className="title"
              sx={{
                position: "absolute",
                top: { xs: "-35px", sm: "-45px", md: "-60px" },
                left: { xs: "65%", sm: "50%", md: "58%" },
                transform: "translate(-50%,-50%)",
                fontSize: { xs: "15px",md:"25px" },
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              mart
            </Typography>
          </Link>
        </Box>

        <Box
          // className="item item3"
          // sx={{ bottom: {xs: "45%",sm:"45%", md: "44.5%",},  right: {xs: "32%",sm:"32.5%", md: "31%",},width:{xs:"30px",sm:"40px",md:"55px"},height:{xs:"30px",sm:"40px",md:"55px"} }}>
          className="item item7"
          sx={{
            bottom: { xs: "32%", sm: "31%", md: "31%" },
            left: { xs: "19%", sm: "19%", md: "19%" },
            width: { xs: "40px", sm: "50px", md: "80px" },
            height: { xs: "40px", sm: "50px", md: "80px" },
          }}
        >
          <Link href="https://qudraat.com">
          {/* <Link href="https://qudraatacademy.com"> */}
            <img src="./images/1.png" alt="media" />
            <Typography
              className="title"
              sx={{
                position: "absolute",
                top: { xs: "-40px", sm: "-55px", md: "-80px" },
                left: { xs: "52%", sm: "55%", md: "50%" },
                transform: "translate(-50%,0)",
                fontSize: { xs: "15px",md:"25px"},
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              {/* academy */}
              Qudraat
            </Typography>
          </Link>
        </Box>

        <Box
          className="item item2"
          sx={{
            bottom: { xs: "47.5%", sm: "48%", md: "47%" },
            right: { xs: "45%", sm: "45%", md: "44.5%" },
            width: { xs: "40px", sm: "50px", md: "85px" },
            height: { xs: "40px", sm: "50px", md: "85px" },
          }}
        >
          <Link href="https://qudraaty.com">
            <img src="./images/x.png" alt="media" />
            <Typography
              className="title"
              sx={{
                position: "absolute",
                top: { xs: "-45px", sm: "-50px", md: "-80px" },
                left: { xs: "", sm: "", md: "50%" },
                transform: "translate(-50%,0)",
                fontSize: { xs: "15px",md:"25px" },
                fontWeight: "bold",
                textTransform: "uppercase",
              }}
            >
              qudraaty
            </Typography>
          </Link>
        </Box>
      </Box>

      <Link href="https://wa.me/+201225681111">
        <Box
          sx={{
            position: "absolute",
            inset: "auto 20px 20px auto",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            background: "white",
            color:"black",
            padding:"5px",
            borderRadius: "25px",

          }}
        >
          <Typography sx={{margin:"5px"}}>Contact Us </Typography>
          <IconButton sx={{ background: "#25D266" }}>
            <WhatsAppIcon fontSize="midum" />
          </IconButton>
        </Box>
      </Link>
    </Box>
  );
}

export default Header;

{
  /* <Box
          className="item item1"
          sx={{ bottom: {xs: "17%", sm: "17%",md: "17%"}, left: {xs: "12%",sm: "13%",md: "12.5%"},width:{xs:"30px",sm:"40px",md:"50px"},height:{xs:"30px",sm:"40px",md:"50px"}}}>
          <Link href="https://up.qudraat.com">
            <img src="./images/up.png" alt="media"/>
          <Typography className="title" sx={{position:'absolute',top:{xs:"-28px",sm:"-45px",md:"-45px"},left:{xs:"-20%",sm:"-25%",md:"-25%"},transform:'translate(-50%,0) rotate(-35deg)',fontSize:{xs:"10px"},fontWeight:"bold"}}>  UP </Typography>
          </Link>
        </Box> */
}

{
  /* <Box
          className="item item5"
          sx={{ bottom: {xs: "17%", sm: "17%", md: "17%" }, right: {xs: "12%",sm: "13%", md: "12%"},width:{xs:"30px",sm:"40px",md:"55px"},height:{xs:"30px",sm:"40px",md:"55px"} }}>
          <Link href="https://tech.qudraat.com">
          <img src="./images/tech.png" alt="media"/>
          <Typography className="title" sx={{position:'absolute',top:{xs:"-33px",sm:"-45px",md:"-45px"},left:{xs:"45%",sm:"50%",md:"70%"},transform:'translate(-50%,0) rotate(-35deg)',fontSize:{xs:"10px"},fontWeight:"bold"}}>  TECH </Typography>
          </Link>
        </Box> */
}

{
  /*  <Box
          className="item item7"
          sx={{ bottom: {xs: "32%",sm:"32%", md: "31%",}, left: {xs: "20%",sm:"20%", md: "19.5%",},width:{xs:"30px",sm:"40px",md:"55px"},height:{xs:"30px",sm:"40px",md:"55px"}}} >
          <Link href="https://venture.qudraat.com">
          <img src="./images/venture.png" alt="media"/>
          <Typography className="title" sx={{position:'absolute',top:{xs:"-40px",sm:"-50px",md:"-55px"},left:{xs:"20%",sm:"35%",md:"40%"},transform:'translate(-50%,0)',fontSize:{xs:"10px"},fontWeight:"bold"}}>  VENTURE </Typography>
          </Link>
        </Box>  */
}

{
  /* <Box
          className="item item4"
          sx={{ bottom: {xs: "45%", sm:"44%",md: "44%",},  left: {xs: "31%",sm:"30.5%", md: "30%",},width:{xs:"30px",sm:"44px",md:"55px"},height:{xs:"30px",sm:"44px",md:"55px"} }}>  
          <Link href="https://business.qudraat.com">
          <img src="./images/business.png" alt="media"/>
          <Typography className="title" sx={{position:'absolute',top:{xs:"-40px",sm:"-55px",md:"-60px"},left:{xs:"52%",sm:"55%",md:"50%"},transform:'translate(-50%,0)',fontSize:{xs:"10px"},fontWeight:"bold"}}>  BUSINESS </Typography>
          </Link>
        </Box> */
}
