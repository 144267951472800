import { Box } from '@mui/material';
import './App.css';

import Header from './Header';

function App() {
  return (
  <Box sx={{position:'relative'}}>
    <Header/>
  </Box>
  );
}

export default App;
